import { faArrowRight, faBars, faHouse, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const posterSrc = 'https://dt7j04u1665qi.cloudfront.net/LandingBackground.webp'
const logo = 'https://dt7j04u1665qi.cloudfront.net/all_brands_logos_170-40.webp'

const LandingPage = () => {
  const videoSrc1 = "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/i.prod/Main_Video_Crop_1_4_2_Mb_2ae1abc7a0.mp4"
  const videoSrc2 = "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/i.prod/Home_page_1_a6bc0ce7a5.mp4"
  const [showMenu, setShowMenu] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust the threshold based on your mobile design needs
    };

    checkIsMobile(); // Initial check

    // Update isMobile on window resize
    window.addEventListener('resize', checkIsMobile);

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  const videoSrc = isMobile ? videoSrc2 : videoSrc1;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  useEffect(() => {
    // Simulate delay for logo animation (You can adjust the delay value)
    const logoAnimationDelay = setTimeout(() => {
      setShowLogo(true);
    }, 500);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(logoAnimationDelay);
  }, []);

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <header id="home" className="tracking-widest relative h-screen">
      {videoLoaded ? null : (
        <div className="w-full h-full object-cover absolute inset-0 z-0">
          <img
            src={posterSrc}
            className="w-full h-full object-cover"
            alt="Video Poster"
          />
        </div>
      )}
      <video
        autoPlay
        loop
        muted
        playsInline
        className={`w-full h-full object-cover absolute inset-0 z-0 ${videoLoaded ? 'opacity-100' : 'opacity-0'}`}
        src={videoSrc}
        onLoadedData={handleVideoLoad}
      ></video>
      {/* ... (Video and background styles remain the same) */}
      <div className='bg-black bg-opacity-50 flex flex-col relative h-screen overflow-hidden'>
        <div className=" p-4 inset-0 z-9">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <div className="flex items-center">
              {showLogo && (
                <div className={`w-1/3 md:w-1/2 lg:w-1/3 xl:w-1/3 text-white ${showLogo ? 'animate-slideInLeft' : ''}`}> {/* Adjust width for different screen sizes */}
                  <img
                    src={logo}
                    className="w-full h-auto" // Ensure the image width adjusts responsively
                    alt="Logo"
                  />
                </div>
              )}
            </div>

            {/* Mobile Menu */}
            <div className="md:hidden">
              <button aria-label="Menu" onClick={toggleMenu} className="text-white btn-header">
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>


            {/* Mobile Menu */}
            <div
              className={`fixed inset-0 bg-white z-20 transform transition-transform duration-300 ${showMenu ? 'translate-x-0' : '-translate-x-full'
                } `}
              style={{
                width: '70%', // Set the width to 70% of the screen
                maxHeight: '100%', // Ensure the menu covers the entire height
                overflowY: 'auto', // Enable scrolling if needed
              }}
            >
              <nav className="p-8">
                <div className="flex flex-col space-y-4">
                  <a href="#home" className="text-2xl font-bold">HAIDERIES</a>
                  <Link to='/Roadshows'>
                    <button className="btn-header text-xs bg-gray-200 w-full md:text-xl">Roadshows</button>
                  </Link>
                  <Link to='/Contact'>
                    <button className="btn-header text-xs bg-gray-200 w-full  md:text-xl">Contact</button>
                  </Link>
                  <Link to='/WhyHaideries'>
                    <button className="btn-header text-xs bg-gray-200 w-full  md:text-xl">About</button>
                  </Link>
                  <button onClick={closeMenu} className="btn-header text-xs bg-red-200 w-full md:text-xl">CLOSE</button>
                </div>
              </nav>
            </div>


            {/* Navigation Buttons (Desktop) */}
            <nav className="hidden font-light md:flex space-x-4">
              <button className="btn-header text-white"><FontAwesomeIcon icon={faSearch} className='px-2' /> PROPERTY SEARCH</button>
              <div className="border-r border-white"></div>
              <Link to="/ListProperty">
                <button className="btn-header text-white"><FontAwesomeIcon icon={faHouse} className='px-2' /> LIST YOUR PROPERTY</button>
              </Link>
              <div className="border-r border-white"></div>
              <button className="btn-header text-white"><FontAwesomeIcon icon={faBars} className='px-2' onClick={toggleMenu} /> MENU</button>
            </nav>
          </div>
        </div>
        <div className=" absolute top-20 inset-0 z-9 flex flex-col justify-evenly">

          {/* Centered heading section */}
          <div className='flex flex-col  md:mx-4 mid-lg:mx-24 lg:mx-48'>
            <h1 className={`tracking-widest text-3xl px-1 md:text-6xl lg:text-7xl text-white font-thin text-center md:text-left ${showLogo ? 'animate-slideInLeft' : ''}`}>
              GLOBALLY CONNECTED
            </h1>
            <h1 className={`tracking-widest text-3xl px-1 md:text-6xl lg:text-7xl text-white font-thin text-center md:text-right ${showLogo ? 'animate-slideInRight' : ''}`}>
              LOCALLY ROOTED...
            </h1>
          </div>
          <div className="flex justify-center ">
            <div className="w-full mx-6 p-8 md:mx-36 mid-lg:mx-48 lg:mx-72 md:p-16 lg:w-full bg-white/15 backdrop-blur-sm text-white text-center">
              <div className="tracking-tighter md:tracking-wide lg:tracking-widest mb-4 text-sm md:text-lg lg:text-base flex justify-center">
                <div className="mx-4">BUY</div>
                <div className="mx-4">RENT</div>
                <div className='mx-4'>OFFPLAN</div>
              </div>
              {/* Search bar */}
              <div className='flex flex-col justify-center md:flex-row'>
                <div className="w-full md:w-4/6">
                  <form onSubmit={(e) => { e.preventDefault(); /* Add functionality for search action here */ }}>
                    <input
                      type="text"
                      placeholder="City, Community or Area"
                      className="text-xs md:text-base w-full bg-white md:rounded-l-sm py-3 px-6 text-black placeholder-gray-500 focus:outline-none"
                    />
                  </form>
                </div>
                <div className='w-full md:w-1/5 mt-4 md:mt-0 flex'>
                  <button className='w-full md:w-auto md:rounded-r-sm bg-black py-3 px-4'>
                    SEARCH
                  </button>
                </div>
              </div>
            </div>

          </div>
          <div className="flex justify-center mb-16">
            <h2 className='text-white text-xs md:text-sm flex flex-col md:flex-row'>
              Do you need to Sell or Rent your Property?
              <Link to='/ListProperty' className='flex justify-center font-bold underline ml-2'>
                List Your Property
                <FontAwesomeIcon className="ml-2 mt-1" icon={faArrowRight} style={{ color: "#ffffff", transform: "rotate(-30deg)" }} />
              </Link>
            </h2>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingPage;