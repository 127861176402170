import React, { useState, useEffect, useMemo, useRef } from 'react';
import Slider from 'react-slick';
import '../asset/RoadShow.css'

function RoadShow() {
  const [width, setWidth] = useState(window.innerWidth);
  const missionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }
  }, [missionRef]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = useMemo(() => {
    if (width < 1024 && width > 786) {
      return {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
      };
    } else if (width < 786 && width > 460) {
      return {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      };
    } else if (width < 460) {
      return {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      };
    } else {
      return {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
      };
    }
  }, [width]);

  const data = [
    {
      "place": "Downtown Dubai",
      "date": "Dubai, UAE",
      "area": "1200 sq ft",
      "rooms": 3,
      "bathroom": 2,
      "price": 75000,
      "imgUrl": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/x.prod/510x/Dowtown_Dubai_1_f2287e69e7.webp",
    },
    {
      "place": "Business Bay",
      "date": "Dubai, UAE",
      "area": "1500 sq ft",
      "rooms": 5,
      "bathroom": 3,
      "price": 150000,
      "imgUrl": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/x.prod/510x/Business_Bay_1_e83144042f.webp",
    },
    {
      "place": "City Walk",
      "date": "Dubai, UAE",
      "area": "320 sq ft",
      "rooms": 3,
      "bathroom": 2,
      "price": 45000,
      "imgUrl": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/x.prod/510x/City_walk_1_b73fae49a1.webp",
    },
    {
      "place": "Dubai Harbour",
      "date": "Dubai, UAE",
      "area": "1200 sq ft",
      "rooms": 3,
      "bathroom": 2,
      "price": 75000,
      "imgUrl": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/x.prod/510x/Dubai_Harbour_1_e053696c46.webp",
    },
    {
      "place": "Palm Jumeirah",
      "date": "Dubai, UAE",
      "area": "320 sq ft",
      "rooms": 3,
      "bathroom": 2,
      "price": 45000,
      "imgUrl": "https://dt7j04u1665qi.cloudfront.net/PJ.webp",
    },
    {
      "place": "JVC Dubai",
      "date": "Dubai, UAE",
      "area": "1200 sq ft",
      "rooms": 3,
      "bathroom": 2,
      "price": 75000,
      "imgUrl": "https://dt7j04u1665qi.cloudfront.net/JVC_dubai.webp",
    }
  ]

  const Card = ({ place, date, imgUrl }) => (
    <div className={`mx-2 py-4 ${isVisible ? 'animate-slideInTop2 opacity-100' : 'opacity-0'}`}>
      <div className="shadow-md overflow-hidden cursor-pointer transition-transform duration-250 ease-in-out hover:transform hover:-translate-y-1 hover:shadow-lg">
        <div className="image-container">
          <img
            src={imgUrl}
            loading="lazy" alt="dnke"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="pt-2">
        <h2 className="text-xl font-bold">{place}</h2>
        <p>{date}</p>
      </div>
    </div>
  );

  return (
    <div ref={missionRef} className='w-full py-8 pb-12 md:pb-16 overflow-hidden'>
      <div className="mx-2 md:mx-8 mid-lg:mx-24 lg:mx-48 lg:px-6">
        <h1 className={`text-2xl md:text-3xl lg:text-4xl p-1 py-4 ${isVisible ? 'animate-slideInLeft opacity-100' : 'opacity-0'}`}>PROMINENT AREAS IN DUBAI</h1>
        <p className={`px-1 py-4 max-w-2xl tracking-wide text-xs md:text-sm transition-all duration-1000} ${isVisible ? 'animate-slideInRight opacity-100' : 'opacity-0'}`}>
          Discover your ideal residence in Dubai amidst the highly desirable communities, ranging from the iconic Downtown to the luxurious Palm Jumeirah. Explore the finest locales for purchasing property in Dubai and find your dream home today.
        </p>
        <Slider {...settings} className="slick-carousel slick-carousel-1">
          {data.map((card, it) => (
            <Card
              key={it}
              place={card.place}
              date={card.date}
              imgUrl={card.imgUrl}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default RoadShow;
