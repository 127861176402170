import React from 'react';
import { Link } from 'react-router-dom';


function Footer() {
    return (
        <div className='bg-colourBg'>
            <div className='text-white px-4 md:px-8 mid-lg:mx-24 lg:mx-48'>
                {/* White line at the start */}
                <div className='p-1 py-16 text-sm'>
                    <h1 className='text-sm md:text lg:text-xl '>START YOUR JOURNEY WITH HAIDERIES</h1>
                    <Link to="/"            ><button className='text-black bg-white rounded px-16 py-3 border mr-2 mt-4 md:mb-8 tracking-widest hover:text-white hover:bg-colourBg '>FIND PROPERTY</button></Link>
                    <Link to='/ListProperty'><button className='text-white bg-colourBg rounded px-16 py-3 border md:ml-2 mt-4 md:mb-8 tracking-widest hover:text-black hover:bg-white'>LIST PROPERTY<span className='invisible'>.</span></button></Link>
                </div>
                <div className="border-t border-white mb-4"></div>

                {/* Footer content with grid */}
                <div className='text-xs text-colourFt py-12 grid grid-cols-2 md:grid-cols-3 mid-lg:grid-cols-5 lg:grid-cols-5 gap-8'>
                    {/* Column 1 */}
                    <div className='mb-4'>
                        <h2 className='text-sm text-white font-semibold mb-4'>BUY PROPERTY</h2>
                        <ul className='list-none'>
                            <li className='my-2 hover:text-white'><Link to='/ComingSoon'>Properties for sale</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/BuyWithHaideries'>Buying with Haideries</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/BuyersGuide'>Buyers Guide</Link></li>
                            {/* <li className='my-2 hover:text-white'><Link to='/'>Dubai communities</Link></li> */}
                        </ul>
                    </div>

                    {/* Column 2 */}
                    <div className='mb-4'>
                        <h2 className='text-sm text-white font-semibold mb-4'>SELL PROPERTY</h2>
                        <ul className='list-none'>
                            <li className='my-2 hover:text-white'><Link to='/BookValuation'>Book a valuation</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/ListProperty'>List your Property</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/SellYourProperty'>Sell with Haideries</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/SellersGuide'>Seller Guide</Link></li>
                        </ul>
                    </div>

                    {/* Column 2 */}
                    <div className='mb-4'>
                        <h2 className='text-sm text-white font-semibold mb-4'>RENT PROPERTY</h2>
                        <ul className='list-none'>
                            <li className='my-2 hover:text-white'><Link to='/ComingSoon'>Properties for rent</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/RentWithHaideries'>Why rent with Haideries</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/TenantsGuide'>Tenants Guide</Link></li>
                            {/* <li className='my-2 hover:text-white'><Link to='/'>Tenant FAQs</Link></li> */}
                        </ul>
                    </div>

                    {/* Column 2 */}
                    <div className='mb-4'>
                        <h2 className='text-sm text-white font-semibold mb-4'>ABOUT</h2>
                        <ul className='list-none'>
                            <li className='my-2 hover:text-white'><Link to='/WhyHaideries'>Why Haideries</Link></li>
                            {/* <li className='my-2 hover:text-white'><Link to='/'>Our Partners</Link></li> */}
                        </ul>
                    </div>

                    {/* Column 2 */}
                    <div className='mb-4'>
                        <h2 className='text-sm text-white font-semibold mb-4'>CONTACT</h2>
                        <ul className='list-none'>
                            <li className='my-2 hover:text-white'><Link to='/Contact'>General Enquiry</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/TermsAndConditions'>Terms and Conditions</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/PrivacyPolicy'>Privacy Policy</Link></li>
                            <li className='my-2 hover:text-white'><Link to='/CookiePolicy'>Cookie Policy</Link></li>
                        </ul>
                    </div>
                    {/* Add more columns as needed */}
                </div>

                {/* White line at the end */}
                <div className="border-b border-white mt-4"></div>
                <div className='text-sm md:text lg:text-xs p-1 py-10 mb-16 md:mb-0'>© 2024 Haideries Real Estate. All Rights Reserved.</div>

            </div>
        </div>
    );
}

export default Footer;
