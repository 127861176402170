import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

  }, [missionRef]);

  return (
    <div ref={missionRef} className='py-10 px-4 md:px-8 mid-lg:mx-24 lg:mx-48 flex flex-col items-center'>
      <div className='w-full md:w-auto flex flex-col md:flex-row justify-center items-center'>
        <img className={`w-full md:w-1/2 pr-0 md:pr-2 mb-4 md:mb-0 transition-all duration-700
          ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`}
          src="https://dt7j04u1665qi.cloudfront.net/36-1-small.webp"
          srcSet="
            https://dt7j04u1665qi.cloudfront.net/36-1-small.webp 400w,
            https://dt7j04u1665qi.cloudfront.net/36-1-medium.webp 800w,
            https://dt7j04u1665qi.cloudfront.net/36-1-large.webp 1200w
          "
          sizes="(max-width: 600px) 400px, (max-width: 1200px) 800px, 1200px"
          alt=""
        />
        <div className='w-full md:w-1/2 pl-0 md:pl-8'>
          <h1 className={`text-2xl md:text-4xl mb-4 lg:pb-8 transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>ABOUT HAIDERIES REAL ESTATE</h1>
          <p className={`md:text-lg transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
            Haideries is an integrated real estate service provider, ranked UAE’s Number 1 agency in the United Arab Emirates, offering a comprehensive range of services to clients from local and international backgrounds. Unlike conventional real estate companies, Haideries designed a unique framework that focuses on improving clients’ experiences by providing them with appropriate tools, unbiased guidance, and in-depth knowledge about specific locations. Their approach aims to empower clients and ensure their satisfaction throughout the real estate process.
          </p>
          <div className='pt-8 md:text-xl grid grid-cols-2 gap-2 md:gap-8 my-4'>
            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
              <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faCreditCard} />
              <div className='m-2 mx-4'>
                <div>20 BILLION</div>
                <div className='text-sm'>Total Value</div>
              </div>
            </div>
            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
              <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faLanguage} />
              <div className='m-2 mx-4'>
                <div>20 +</div>
                <div className='text-sm'>Language Spoken</div>
              </div>
            </div>
            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
              <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faUser} />
              <div className='m-2 mx-4'>
                <div>4.9/5</div>
                <div className='text-sm'>Customer Rating</div>
              </div>
            </div>
            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
              <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faHouse} />
              <div className='m-2 mx-4'>
                <div>5000+</div>
                <div className='text-sm'>Properties Sold so far</div>
              </div>
            </div>
          </div>
          <div className='my-8'>
            <button className='px-8 py-4 border-b-2 font-bold text-bluColor hover:text-white hover:bg-bluColor' style={{ borderColor: "#4B6587" }}>Find Out More <FontAwesomeIcon className='px-4' icon={faArrowRight} /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
