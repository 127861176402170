import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LandingPage from './components/LandingPage'
import Mission from './components/Missions'
import Properties from './components/Properties'
import About from './components/About'
import Faq from './components/FAQ'
import Market from './components/Market'
import Testimonial from './components/Testimonial'
import Explore from './components/Explore'
import RoadShow from './components/RoadShow'

const App = () => {
  return (
    <div className='tracking-wider font-Figtree bg-colour relative'>
        <LandingPage />
        <Mission />
        <Properties />
        <About />
        <Faq />
        <Market />
        <RoadShow />
        <Testimonial />
        <Explore />
    </div>
  );
};

export default App;
