import React, { useEffect, useRef, useState } from 'react';

const Mission = () => {
  const [isVisible, setIsVisible] = useState(false);
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.40 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }
  }, [missionRef]);

  return (
    <div ref={missionRef} className='bg-img-bg bg-repeat'>
      <div className="py-20 px-4 md:px-8 mid-lg:px-24 lg:px-48 bg-colour bg-opacity-95 flex flex-col md:flex-row justify-center gap-8">
        <div className={`flex flex-col justify flex-1 transition-all duration-1000 ${isVisible ? 'animate-slideInTop' : ''}`}>
          <div>
            <h1 className='mx-4 md:m-4 text-3xl md:text-4xl'>OUR MISSION</h1>
          </div>
        </div>
        <div className={`tracking-wide flex flex-col justify flex-1 transition-all duration-1000 ${isVisible ? 'animate-slideInTop1' : ''}`}>
          <div>
            <p className='mx-4 md:m-4 text-sm md:text-base'>
              Haideries, as a Dubai-based real estate company, Our goal is to be the foremost facilitator of exceptional real estate experiences in the vibrant and ever-evolving landscape of Dubai. We are dedicated to redefining the way people buy, sell, and invest in real estate in this dynamic city.
            </p>
          </div>
        </div>
        <div className={`tracking-wide flex flex-col justify flex-1 transition-all duration-1000 ${isVisible ? 'animate-slideInTop2' : ''}`}>
          <div>
            <h1 className='mx-4 md:m-4 text-sm md:text-base'>
              Our core values of client-centricity, innovation, and integrity drive us to prioritize our clients’ unique needs and aspirations above all else. We continuously embrace innovative technologies and stay at the forefront of market trends to provide our clients with the most insightful and effective solutions.
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
