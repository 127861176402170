import React from 'react';
import { useState, useEffect, useRef } from 'react';

const Agent = 'https://dt7j04u1665qi.cloudfront.net/Agent.webp'
function WeDoBetter() {
    const createObserver = (ref, setIsVisible) => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.2 }
        );
        return observer
    };

    const observeRef = (ref, observer) => {
        if (ref.current) {
            observer.observe(ref.current);
        }
    };

    const [isVisible, setIsVisible] = useState(false);
    const missionRef = useRef(null);

    useEffect(() => {
        const observer = createObserver(missionRef, setIsVisible);

        observeRef(missionRef, observer);

        if (missionRef.current) {
            observer.observe(missionRef.current);
        }

    }, [missionRef]);


    return (
        <>
            <div ref={missionRef} className='pb-10 px-4 md:px-8 mid-lg:mx-24 lg:mx-48 flex flex-col items-center'>
                <div className='w-full md:w-auto flex flex-col md:flex-row justify-center'>
                    <img loading="lazy" alt='We Do Better' className={`w-full md:w-1/2 pr-0 md:pr-2 mb-4 md:mb-0 transition-all duration-700 ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`} src={Agent} />
                    <div className='w-full md:w-1/2 pl-0 md:pl-8'>
                        <h1 className={`text-2xl md:text-4xl mb-4 lg:pb-2 transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>WE DO BETTER</h1>
                        <p className={`text-sm md:text-base transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Haideries can help you navigate the complex legal and administrative aspects of the sales process. We can assist you with paperwork, contracts, and negotiations with potential buyers, which can help you avoid misunderstandings and potential legal issues. Working with us can help ensure that the sales process goes smoothly and efficiently. Overall, listing your property with Haideries Real Estate can help you maximize the value of your property and make the sales process easier and more convenient.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WeDoBetter