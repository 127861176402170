import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonShelter } from '@fortawesome/free-solid-svg-icons'
import { faBed } from '@fortawesome/free-solid-svg-icons'
import { faBath } from '@fortawesome/free-solid-svg-icons'
import { faSackDollar } from '@fortawesome/free-solid-svg-icons'

const Properties = ({ele, it}) => {
  const [isVisible, setIsVisible] = useState(false);
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

  }, [missionRef]);

  return  (
    <div ref={missionRef} key={it} className={`flex justify-center mb-4 md:mr-2 w-full md:w-1/3 transition-all duration-1000 ${isVisible ? 'animate-slideInTop1 opacity-100' : 'opacity-0'}`}>
      <div>
        <img
          loading="lazy"
          decoding="async"
          src={ele.image}
          alt=""
          srcSet={ele.srcSet}
          sizes="(max-width: 600px) 400px, (max-width: 1200px) 800px, 1200px"
          className='max-w-full h-auto'
        />
        <h2 className='text-xl font-bold'>{ele.name}</h2>
        <h3 className='text-sm my-2 text-gray-700'>{ele.location}</h3>
        <div className='text flex justify between'>
          <p className=''><FontAwesomeIcon className="mr-4" style={{color:"#4B6587"}} icon={faPersonShelter} />{ele.area}</p>
          <p className=''><FontAwesomeIcon className="mx-4" style={{color:"#4B6587"}} icon={faBed} /> {ele.rooms}</p>
          <p className=''><FontAwesomeIcon className="mx-4" style={{color:"#4B6587"}} icon={faBath} />{ele.bathroom} </p>
          <p className=''><FontAwesomeIcon className="mx-4" style={{color:"#4B6587"}} icon={faSackDollar} />{ele.price}</p>
        </div>
      </div>
    </div>
  );
};

export default Properties;
