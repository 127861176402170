import { faBars, faHouse, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GoTop from '../Tools/GoTop';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const pfp1 = "https://dt7j04u1665qi.cloudfront.net/Haideries-main-logo.webp"
const Header = ({visible}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsHeaderVisible(scrollPosition >= 8);
      
    };

    // Listen for scroll events
    window.addEventListener('scroll', handleScroll);

    // Simulate delay for logo animation (You can adjust the delay value)
    const logoAnimationDelay = setTimeout(() => {
      setShowLogo(true);
    }, 500);

    // Clean up event listener and timeout to prevent memory leaks
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(logoAnimationDelay);
    };
  }, []);

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
    <GoTop />
    <header
      id="home"
      className={`tracking-widest relative z-10 ${isHeaderVisible || visible ? '' : 'hidden'}`}
    >
      <div className="bg-white h-auto fixed w-full flex flex-col justify-between">
        <div className="py-2 px-4">
          <div className="max-w-7xl mx-auto flex items-center justify-between">
            <Link to='/' className="flex items-center" onClick={() => {window.scrollTo({top:0, behavior: "smooth"})}}>
              {showLogo && (
                <div className="w-1/3 md:w-64 lg:w-1/3 xl:w-64 text-black">
                  {/* Adjust width for different screen sizes */}
                  <img
                    src={pfp1}
                    className="w-full h-auto" // Ensure the image width adjusts responsively
                    loading="lazy" alt="Logo"
                  />
                </div>
              )}
            </Link>

            {/* Mobile Menu */}
            <div className="md:hidden">
              <button onClick={toggleMenu} className="text-black btn-header">
                <FontAwesomeIcon icon={faBars} />
              </button>
            </div>

            {/* Mobile Menu */}
            <div
              className={`fixed inset-0 bg-white z-20 transform transition-transform duration-300 ${showMenu ? 'translate-x-0' : '-translate-x-full'
                }`}
              style={{
                width: '70%', // Set the width to 70% of the screen
                maxHeight: '100%', // Ensure the menu covers the entire height
                overflowY: 'auto', // Enable scrolling if needed
              }}
            >
              <nav className="p-8">
                <div className="flex flex-col space-y-4">
                  <a href="#home" className="text-2xl font-bold">HAIDERIES</a>
                  <Link to='/Roadshows'>
                    <button className="btn-header text-xs bg-gray-200 w-full md:text-xl">Roadshows</button>
                  </Link>
                  <Link to='/Contact'>
                    <button className="btn-header text-xs bg-gray-200 w-full  md:text-xl">Contact</button>
                  </Link>
                  <Link to='/WhyHaideries'>
                    <button className="btn-header text-xs bg-gray-200 w-full  md:text-xl">About</button>
                  </Link>
                  <button onClick={closeMenu} className="btn-header text-xs bg-red-200 w-full md:text-xl">CLOSE</button>
                </div>
              </nav>
            </div>

            {/* Navigation Buttons (Desktop) */}
            <nav className="hidden font-light md:flex space-x-4">
              <button className="btn-header text-black"><FontAwesomeIcon icon={faSearch} className='px-2'/> PROPERTY SEARCH</button>
              <div className="border-r border-black"></div>
              <Link to='/ListProperty'>
                <button className="btn-header text-black"><FontAwesomeIcon icon={faHouse} className='px-2'/> LIST YOUR PROPERTY</button>
              </Link>
              <div className="border-r border-black"></div>
              <button className="btn-header text-black"><FontAwesomeIcon icon={faBars} onClick={toggleMenu} className='px-2'/> MENU</button>
            </nav>
          </div>
        </div>
      </div>
    </header>
      <div className="fixed w-full bottom-0 md:w-auto md:bottom-8 md:right-8 flex md:space-x-4 z-10 md:text-3xl">
        <div className="w-1/2 bg-white md:bg-green-500 text-white md:rounded-full flex items-center justify-center p-2 md:w-12 md:h-12">
          <a 
            href='https://wa.me/+971502607861'
            target="_blank"
            rel="noopener noreferrer"
            className="w-full bg-black md:bg-green-500 text-white md:rounded-full flex items-center justify-center p-4 md:w-12 md:h-12"
            aria-label="Open WhatsApp"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
          </a>
        </div>
        <div className="w-1/2 bg-white md:bg-gray-500 text-white md:rounded-full flex items-center justify-center p-2 md:w-12 md:h-12">
          <a
            href='tel:+971502607861'
            target="_blank"
            rel="noopener noreferrer"
            className="w-full bg-black md:bg-gray-500 text-white md:rounded-full flex items-center justify-center p-4 md:w-12 md:h-12"
            aria-label="Call Us"
            >
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </div>
      </div>
    </>
  );
};

export default Header;
