import React, { useEffect, useRef, useState } from 'react';
import Prop from './Prop'

import data from '../Data.json'

const Properties = () => {
  const [isVisible, setIsVisible] = useState(false);
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

  }, [missionRef]);


  return  (
    <div ref={missionRef} className='py-10 px-4 md:px-8 mid-lg:mx-24 lg:mx-48'>
      <h1 className={`text-3xl md:text-4xl lg:text-4xl p-1 mb-8 ${isVisible ?  'animate-slideInLeft opacity-100' : 'opacity-0'}`}>FEATURED PROPERTIES IN DUBAI</h1>
      <div className='flex flex-col md:flex-row justify-between items-center'>
        {data.properties.map((ele, it) => <Prop key={it} ele={ele} it={it}/>)}
      </div>
    </div>
  );
};

export default Properties;
