import React from 'react'
import GuideTemplate from '../template/GuideTemplate'
import GuideToSellData from '../../SellGuideData.json'

function GuideToSell() {
  return (
    <div>
        <GuideTemplate 
            header={'GUIDE TO SELLING PROPERTY IN DUBAI'}
            subHeading={'Want to Sell your Property? Get the right knowledge from our experts or refer to the guide below!'}
            data={GuideToSellData}
        />
    </div>
  )
}

export default GuideToSell