import { faBars, faHouse, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import RequestCallback from '../template/RequestCallback';
import WeDoBetter from '../template/WeDoBetter';
import DescriptionTemplate from '../template/DescriptionTemplate';
const posterSrc = 'https://dt7j04u1665qi.cloudfront.net/SellYourProperty.webp'

const WhyChooseHaideries = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [showLogo, setShowLogo] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const [
        heading,
        subHeading,
        desc,
        paraText,
        paraText2,
    ] = [
        'WHY CHOOSE HAIDERIES ?',
        'WHY CHOOSE HAIDERIES ?',
        'Haideries Real Estate is an integrated real estate service provider offering a world-class real estate service to individuals and institutional clients.',
        'At Haideries Real Estate, our vision is to be your preferred partner for all your Dubai real estate needs. Our mission is centered on simplifying your investment journey, ensuring it is seamless and satisfying through our integrated, data-driven, and technically advanced platform. Over the last 24 months, we\'ve successfully achieved a total sale of AED 20 billion, showcasing our deep understanding of global real estate demand dynamics.',
        'Headquartered in the UAE, our international expansion within the GCC, Middle East, and Europe, supported by over 200 roadshows since 2020, demonstrates our commitment to providing a realistic and positive image of Dubai. Haideries Real Estate has garnered recognition from renowned developers worldwide, marking milestones in our journey. The strategic opening of offices in Riyadh, Paris, Venice, and Limassol reflects our dedication to broadening horizons and offering unparalleled real estate solutions globally.'
    ]

    useEffect(() => {
        // Simulate delay for logo animation (You can adjust the delay value)
        const logoAnimationDelay = setTimeout(() => {
            setShowLogo(true);
        }, 500);

        // Clean up the timeout to prevent memory leaks
        return () => clearTimeout(logoAnimationDelay);
    }, []);

    const closeMenu = () => {
        setShowMenu(false);
    };

    return (
        <>
            <header id="home" className="tracking-widest relative h-screen">
                <div className="w-full h-full object-cover absolute inset-0 z-0">
                    <img
                        src={posterSrc}
                        className="w-full h-full object-cover"
                        loading="lazy" alt="Video Poster"
                    />
                </div>
                {/* ... (Video and background styles remain the same) */}
                <div className='bg-black bg-opacity-50 flex flex-col relative h-screen'>
                    <div className=" p-4 inset-0 z-9">
                        <div className="max-w-7xl mx-auto flex items-center justify-between">
                            <div className="flex items-center">
                                {showLogo && (
                                    <div className={`w-1/3 md:w-1/2 lg:w-1/3 xl:w-1/3 text-white ${showLogo ? 'animate-slideInLeft' : ''}`}> {/* Adjust width for different screen sizes */}
                                        <img
                                            src="https://dt7j04u1665qi.cloudfront.net/all_brands_logos_170-40.webp"
                                            className="w-full h-auto" // Ensure the image width adjusts responsively
                                            loading="lazy" alt="Logo"
                                        />
                                    </div>
                                )}
                            </div>

                            {/* Mobile Menu */}
                            <div className="md:hidden">
                                <button aria-label="Menu" onClick={toggleMenu} className="text-white btn-header">
                                    <FontAwesomeIcon icon={faBars} />
                                </button>
                            </div>


                            {/* Mobile Menu */}
                            <div
                                className={`fixed inset-0 bg-white z-20 transform transition-transform duration-300 ${showMenu ? 'translate-x-0' : '-translate-x-full'
                                    } md:hidden`}
                                style={{
                                    width: '70%', // Set the width to 70% of the screen
                                    maxHeight: '100%', // Ensure the menu covers the entire height
                                    overflowY: 'auto', // Enable scrolling if needed
                                }}
                            >
                                <nav className="p-8">
                                    <div className="flex flex-col space-y-4">
                                        <a href="#home" className="text-2xl font-bold">HAIDERIES</a>
                                        <button className="btn-header text-xs">PROPERTY SEARCH</button>
                                        <Link to='/ListProperty'>
                                            <button className="btn-header text-xs">LIST YOUR PROPERTY</button>
                                        </Link>
                                        <button onClick={closeMenu} className="btn-header">X</button>
                                    </div>
                                </nav>
                            </div>


                            {/* Navigation Buttons (Desktop) */}
                            <nav className="hidden font-light md:flex space-x-4">
                                <button className="btn-header text-white"><FontAwesomeIcon icon={faSearch} className='px-2' /> PROPERTY SEARCH</button>
                                <div className="border-r border-white"></div>
                                <Link to="/ListProperty">
                                    <button className="btn-header text-white"><FontAwesomeIcon icon={faHouse} className='px-2' /> LIST YOUR PROPERTY</button>
                                </Link>
                                <div className="border-r border-white"></div>
                                <button className="btn-header text-white"><FontAwesomeIcon icon={faBars} className='px-2' /> MENU</button>
                            </nav>
                        </div>
                    </div>
                    <div className=" absolute top-20 inset-0 z-9 flex flex-col justify-evenly">

                        {/* Centered heading section */}
                        <div className='flex flex-col md:mx-4 mid-lg:mx-24 lg:mx-36'>
                            <h1 className={`tracking-widest text-3xl px-1 md:text-5xl lg:text-6xl text-white font-thin text-center ${heading ? '' : 'md:text-left'} ${showLogo ? 'animate-slideInLeft' : ''}`}>
                                {heading}
                            </h1>
                            {heading === 'SELL YOUR PROPERTY WITH' ? <h1 className={`tracking-widest text-3xl px-1 md:text-5xl lg:text-6xl text-white font-thin text-center md:text-right ${showLogo ? 'animate-slideInRight' : ''}`}>
                                HAIDERIES REAL ESTATE
                            </h1> : ''}
                        </div>
                        <div className="flex justify-center item-center">
                            <div className='flex flex-col md:flex-row text-white '>
                                <div className='m-2 text-sm md:text-base w-full mt-4 flex'>
                                    <button className='w-60 md:rounded-r-sm bg-black hover:text-black hover:bg-white py-3 px-4 '>
                                        MEET OUR TEAM
                                    </button>
                                </div>
                                <div className='m-2 text-sm md:text-base w-full mt-4 flex'>
                                    <Link to='/Contact'>
                                        <button className='w-60 md:rounded-r-sm bg-black hover:text-black hover:bg-white py-3'>
                                            CONTACT US
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <DescriptionTemplate 
                heading={heading}
                desc={desc}
                paraText={paraText}
                paraText2={paraText2}
                subHeading={subHeading}
            />
            
            <WeDoBetter />
            <RequestCallback />
        </>
    );
};

export default WhyChooseHaideries;