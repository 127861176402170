import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faBuildingColumns, faHotel, faHouse, faMinus, faPeopleRoof, faPlus, faShop } from '@fortawesome/free-solid-svg-icons'

const Faq = () => {
  const missionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [dropdowns, setDropdowns] = useState({
    homeBuying: true,
    makingOffer: false,
    findingHome: false,
  });

  const toggleDropdown = (dropdownName) => {
    if (!dropdowns[dropdownName]) {
      let d = {
        homeBuying: false,
        makingOffer: false,
        findingHome: false,
      }
      setDropdowns({
        ...d,
        [dropdownName]: !dropdowns[dropdownName],
      });
    } else {
      setDropdowns({
        ...dropdownName,
        [dropdownName]: !dropdowns[dropdownName],
      });
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }
  }, [missionRef]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={missionRef} className='md:my-12 bg-faq-bg bg-repeat flex flex-col items-center overflow-hidden'>
      <div className='py-20 px-4 md:px-8 mid-lg:px-24 lg:px-48 bg-black bg-opacity-20 flex flex-col md:flex-row justify-center items-center'>
        <div className={`w-full md:w-1/2 pr-0 md:pr-2 mb-4 mid-lg:mx-4 lg:mx-8 md:mb-0 transition-all duration-1000`}>
          <div className='pt-8 md:text-xl text-white grid grid-cols-2 md:grid-cols-3 gap-12'>
            <div className={`flex flex-col items-center justify-center text-center ${isVisible && (width < 770) ? 'animate-slideInTop' : isVisible ? 'animate-slideInTop' : ''}`}>
              <div className='text-4xl'><FontAwesomeIcon icon={faBuilding} /></div>
              <div>APPARTMENT</div>
            </div>
            <div className={`flex flex-col items-center justify-center text-center ${isVisible && (width < 770) ? 'animate-slideInTop' : isVisible ? 'animate-slideInTop' : ''}`}>
              <div className='text-4xl'><FontAwesomeIcon icon={faHotel} /></div>
              <div>BUILDING</div>
            </div>
            <div className={`flex flex-col items-center justify-center text-center ${isVisible && (width < 770) ? 'animate-slideInTop1' : isVisible ? 'animate-slideInTop' : ''}`}>
              <div className='text-4xl'><FontAwesomeIcon icon={faPeopleRoof} /></div>
              <div>CONDOMINIUM</div>
            </div>
            <div className={`flex flex-col items-center justify-center text-center ${isVisible && (width < 770) ? 'animate-slideInTop1' : isVisible ? 'animate-slideInTop2' : ''}`}>
              <div className='text-4xl'><FontAwesomeIcon icon={faHouse} /></div>
              <div>HOUSE</div>
            </div>
            <div className={`flex flex-col items-center justify-center text-center ${isVisible && (width < 770) ? 'animate-slideInTop2' : isVisible ? 'animate-slideInTop2' : ''}`}>
              <div className='text-4xl'><FontAwesomeIcon icon={faBuildingColumns} /></div>
              <div>OFFICE</div>
            </div>
            <div className={`flex flex-col items-center justify-center text-center ${isVisible && (width < 770) ? 'animate-slideInTop2' : isVisible ? 'animate-slideInTop2' : ''}`}>
              <div className='text-4xl'><FontAwesomeIcon icon={faShop} /></div>
              <div>SHOP</div>
            </div>
          </div>
        </div>
        <div className={`w-full md:w-1/2 py-2 pl-0 md:ml-16 md:pl-8 mid-lg:mx-4 lg:mx-8 ${isVisible ? 'animate-slideInRight' : ''}`}>
          <div className=''>
            <div onClick={() => toggleDropdown('homeBuying')} className='cursor-pointer'>
              <div className='flex justify-between bg-white font-semibold px-8 py-4'>
                <div>
                  HOME BUYING PROCESS
                </div>
                {dropdowns.homeBuying ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
              </div>
              <div className={`bg-white bg-opacity-90  overflow-hidden transition-all duration-300 ease-in-out ${dropdowns.homeBuying ? 'max-h-[200px] px-8 py-4' : 'max-h-0'}`}>
                Haideries is an integrated real estate service provider,
                ranked UAE’s Number 1 agency in the United Arab Emirates
              </div>
            </div>
          </div>
          <div className=''>
            <div onClick={() => toggleDropdown('makingOffer')} className='cursor-pointer'>
              <div className='flex justify-between bg-white font-semibold px-8 py-4'>
                <div>
                  MAKING AN OFFER
                </div>
                {dropdowns.makingOffer ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
              </div>
              <div className={`bg-white bg-opacity-90  overflow-hidden transition-all duration-300 ease-in-out ${dropdowns.makingOffer ? 'max-h-[200px] px-8 py-4' : 'max-h-0'}`}>
                Haideries is an integrated real estate service provider, ranked UAE’s Number 1 agency in the United Arab Emirates
              </div>
            </div>
          </div>
          <div onClick={() => toggleDropdown('findingHome')} className='cursor-pointer'>
            <div className=''>
              <div className='flex justify-between bg-white font-semibold px-8 py-4'>
                <div>
                  FINDING THE RIGHT HOME
                </div>
                {dropdowns.findingHome ? <FontAwesomeIcon icon={faMinus} /> : <FontAwesomeIcon icon={faPlus} />}
              </div>
              <div className={`bg-white bg-opacity-90  overflow-hidden transition-all duration-300 ease-in-out ${dropdowns.findingHome ? 'max-h-[200px] px-8 py-4' : 'max-h-0'}`}>
                Haideries is an integrated real estate service provider, ranked UAE’s Number 1 agency in the United Arab Emirates
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
