import React, { useState } from 'react';
import Header from '../Header/Header';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import toastTemplate from '../template/toastTemplate';

const ListProperty = ({ header, paraText }) => {

    const {successToast, failureToast, WaitingToast, notify} = toastTemplate

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        propertyAddress: '',
        message: '',
    });

    const [ready, setReady] = useState(false)

    const handleClick = (link) => {
        window.location.href = link;
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (!!formData.name && !!formData.email && !!formData.phoneNumber) {
            setReady(true)
        } else {
            setReady(false)
        }
        
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };    

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Basic form validations
        if (!formData.name || !formData.email || !formData.phoneNumber) {
            alert('Please fill in all required fields.');
            return;
        }

        const waitId = WaitingToast()

        try {
            const response = await axios.post('https://7d4nmtpj4g.execute-api.us-east-1.amazonaws.com/first', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.statusCode === 200) {
                successToast(waitId)
                await setTimeout(() => {
                    notify("We will contact you shortly", "🫰🏼")
                }, 2000)
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    propertyAddress: '',
                    message: '',
                })
                setReady(false)
            } else {
                failureToast(waitId)
                setTimeout(() => {
                    notify("Please Try Again", "😓")
                }, 2500)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Header visible={true} />
            <div>
                <Toaster />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-5 gap-6 md:gap-12 lg:gap-20 w-full bg-colour px-6 md:px-12 mid-lg:px-24 lg:px-36 py-20 md:pt-36 lg:pt-40'>
                <div className='md:col-span-2 grid grid-cols-2 md:flex md:flex-col md:justify-between'>
                    <h1 className='col-span-2 tracking-widest font-thin md:font-light text-3xl md:text-5xl lg:text-5xl mb-4 md:mb-8 lg:mb-10'>
                        {header ? `${header}` : 'LIST YOUR PROPERTY WITH HAIDERIES'}
                    </h1>
                    <div className='col-span-2 max-w-lg tracking-wide text-sm md:text-base lg:text-lg'>
                        {paraText ? `${paraText}` : 'Get the consultation in minutes! We are here to take care of your properties, to connect you with the right buyers. We have sold, rented, and managed thousands of mixed-used properties worth several billions of dirhams. Get in touch with us today.'}
                    </div>
                    <div className='border-b mr-2 md:mr-0 py-4  '>
                        <h2 className='my-2 text-xs text-colourBg font-semibold'>PHONE</h2>
                        <h3 className='text-sm md:tracking-wide my-2 md:text-lg cursor-pointer' onClick={() => handleClick('tel:+971502607861')}>+971 50 260 7861</h3>
                    </div>
                    <div className='border-b ml-2 md:ml-0 py-4  '>
                        <h2 className='my-2 text-xs text-colourBg font-semibold'>WHATSAPP</h2>
                        <h3 className='text-sm md:tracking-wide my-2 md:text-lg cursor-pointer' onClick={() => handleClick('https://wa.me/+971502607861')}>Click to Open WhatsApp</h3>
                    </div>
                    <div className='border-b mr-2 md:mr-0 py-4  '>
                        <h2 className='my-2 text-xs text-colourBg font-semibold'>EMAIL</h2>
                        <h3 className='text-sm md:tracking-widest my-2 md:text-lg cursor-pointer' onClick={() => handleClick('mailto:info@haideries.ae')}>info@haideries.ae</h3>
                    </div>
                    <div className='border-b ml-2 md:ml-0 md:border-0 py-4  '>
                        <h2 className='my-2 text-xs text-colourBg font-semibold'>OFFICE</h2>
                        <h3 className='text-sm md:tracking-widest my-2 md:text-lg'>Dubai, UAE</h3>
                    </div>
                </div>
                <div className='md:col-span-3 w-full bg-white mx-auto p-6 md:p-10 lg:p-12 shadow-lg'>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4 md:mb-8 lg:mb-12">
                            <label htmlFor="name" className="block text-xs font-semibold mb-2">
                                Name
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300"
                                required
                            />
                        </div>
                        <div className="mb-4 md:mb-8 lg:mb-12">
                            <label htmlFor="email" className="block text-xs font-semibold mb-2">
                                Email Address
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300"
                                required
                            />
                        </div>
                        <div className="mb-4 md:mb-8 lg:mb-12">
                            <label htmlFor="phoneNumber" className="block text-xs font-semibold mb-2">
                                Phone Number
                            </label>
                            <input
                                type="number"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300"
                                required
                            />
                        </div>
                        <div className="mb-4 md:mb-8 lg:mb-12">
                            <label htmlFor="propertyAddress" className="block text-xs font-semibold mb-2">
                                Property Address <span className="font-bold">(Optional)</span>
                            </label>
                            <input
                                type="text"
                                id="propertyAddress"
                                name="propertyAddress"
                                value={formData.propertyAddress}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300"
                            />
                        </div>
                        <div className="mb-4 md:mb-6 lg:mb-8">
                            <label htmlFor="message" className="block text-xs font-semibold mb-2">
                                Message <span className="font-bold">(Optional)</span>
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                className="w-full p-2 border border-gray-300"
                            ></textarea>
                        </div>
                        <div className="flex justify-end">
                            <button type="submit" className={`bg-black tracking-widest text-white w-full py-4 text-xs ${ready ? '' : `bg-gray-600 cursor-no-drop`}`}>
                                SUBMIT DETAILS
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ListProperty;
