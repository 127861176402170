import React from 'react'
import GuideTemplate from '../template/GuideTemplate'
import GuideToBuyData from '../../BuyGuideData.json'

function GuideToBuy() {
  return (
    <div>
        <GuideTemplate 
            header={'GUIDE TO BUY PROPERTY IN DUBAI'}
            subHeading={'Want to buy property in Dubai?'}
            data={GuideToBuyData}
        />
    </div>
  )
}

export default GuideToBuy