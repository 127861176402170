import React, { useState, useEffect, useMemo, useRef } from 'react';
import Slider from 'react-slick';

const img3 = 'https://dt7j04u1665qi.cloudfront.net/DOHA_QATAR_MARCH_29_and_30_b99edc58e2.webp'
const img4 = 'https://dt7j04u1665qi.cloudfront.net/Feb_Brussels_f1c6b2b176.webp'
const img5 = 'https://dt7j04u1665qi.cloudfront.net/Feb_doha_b9bd7690ef.webp'
const img6 = 'https://dt7j04u1665qi.cloudfront.net/Feb_Dubai_76030ba370.webp'
const img7 = 'https://dt7j04u1665qi.cloudfront.net/Feb_paris_944a7b0bda.webp'
const img8 = 'https://dt7j04u1665qi.cloudfront.net/Feb_Riyadh_2273ce14c3.webp'
const img9 = 'https://dt7j04u1665qi.cloudfront.net/Feb_Shanghai_d26845f356.webp'
const img10 = 'https://dt7j04u1665qi.cloudfront.net/Jan_Bucharest_8b8731529f.webp'
const img11 = 'https://dt7j04u1665qi.cloudfront.net/Jan_Riyadh_40edce2951.webp'
const img12 = 'https://dt7j04u1665qi.cloudfront.net/Jan_tokyo_5f3ce45375.webp'
const img13 = 'https://dt7j04u1665qi.cloudfront.net/march_21_and_22_2024_82179e5052.webp'

function RoadShow() {
    const [width, setWidth] = useState(window.innerWidth);
    const missionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (missionRef.current) {
            observer.observe(missionRef.current);
        }
    }, [missionRef]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = useMemo(() => {
        if (width < 1024 && width > 786) {
            return {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                arrows: true,
            };
        } else if (width < 786 && width > 460) {
            return {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
            };
        } else if (width < 460) {
            return {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            };
        } else {
            return {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
            };
        }
    }, [width]);

    const props = [
        { id: 3, place: 'Toronto', date: '3-4 February 2024', imgUrl: img3 },
        { id: 4, place: 'Sydney', date: '10-11 February 2024', imgUrl: img4 },
        { id: 5, place: 'Brussels', date: '10-11 February 2024', imgUrl: img5 },
        { id: 6, place: 'Tokyo', date: '17-18 February 2024', imgUrl: img6 },
        { id: 7, place: 'Paris', date: '20-21 February 2024', imgUrl: img7 },
        { id: 8, place: 'Riyadh', date: '20-21 February 2024', imgUrl: img8 },
        { id: 9, place: 'Doha', date: '24-25 February 2024', imgUrl: img9 },
        { id: 10, place: 'Dubai', date: '24 February 2024', imgUrl: img10 },
        { id: 11, place: 'Shanghai', date: '24-25 February 2024', imgUrl: img11 },
        { id: 12, place: 'Paris', date: '9-10 March 2024', imgUrl: img12 },
        { id: 13, place: 'Brussels', date: '16 -17 March 2024', imgUrl: img13 },
    ]

    const Card = ({key, imgUrl, place, date}) => <div  key={key} className={`mx-2 ${isVisible ? 'animate-slideInTop opacity-100' : 'opacity-0'}`}>
        <div className="shadow-md overflow-hidden cursor-pointer transition-transform duration-250 ease-in-out hover:transform hover:-translate-y-1 hover:shadow-lg">
            <img
                src={imgUrl}
                loading="lazy" alt="dnke"
                className="w-full"
            />
        </div>
        <div className="pt-2">
            <h2 className="text-xl font-bold">{place}</h2>
            <p>{date}</p>
        </div>
    </div>

    return (
        <div ref={missionRef}>
            <Slider {...settings}>
                {props.map((card) => (
                    <Card
                        key={card.id}
                        place={card.place}
                        date={card.date}
                        imgUrl={card.imgUrl}
                    />
                ))}
            </Slider>
        </div>
    );
}

export default RoadShow;
