import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.3 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

  }, [missionRef]);

  return (
    <div ref={missionRef} className='py-20 px-4 md:px-8 mid-lg:mx-24 lg:mx-48 flex flex-col items-center'>
      <div className='w-full md:w-auto flex flex-col md:flex-row justify-center items-center'>
        <div className='w-full md:w-1/2'>
          <h1 className={`text-2xl md:text-4xl mb-4 py-2 transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>DUBAI REAL ESTATE MARKET INSIGHTS</h1>
          <p className={`md:text-lg transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
            Access comprehensive information about the current Dubai real estate market. Stay up-to-date with the latest developments regarding various communities, property prices, return on investment potential, and much more—all available at your fingertips with just a single click.
          </p>
          <div className='py-4 md:text-x grid'>
            <div className=''>
              <div><FontAwesomeIcon icon={faCheck} className='mr-4' /> Recent monthly overview of real estate market</div>
            </div>
            <div>
              <div><FontAwesomeIcon icon={faCheck} className='mr-4' /> Learn more about the top communities in Dubai</div>
            </div>
            <div>
              <div><FontAwesomeIcon icon={faCheck} className='mr-4' /> Make informed decisions based on accurate information</div>
            </div>
          </div>
        </div>
        <img className={`w-full md:w-1/2 pl-0 md:pl-2 mb-4 md:mb-0 transition-all duration-700 ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`} src="https://dt7j04u1665qi.cloudfront.net/39-1-small.webp" srcSet="https://dt7j04u1665qi.cloudfront.net/39-1-small.webp 400w, https://dt7j04u1665qi.cloudfront.net/39-1-medium.webp 800w, https://dt7j04u1665qi.cloudfront.net/39-1-medium.webp 1200w" loading="lazy" alt="" />
      </div>
    </div>
  );
};

export default About;
