import React from 'react';
import Header from '../Header/Header';
import BuyGuideData from '../../BuyGuideData.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const GuideTemplate = ({header, data, subHeading}) => {
    let dataToDisplay = BuyGuideData
    if (data) {
        dataToDisplay = data
    }
    return (
        <>
            <Header visible={true} />
            <h1 className='w-full px-6 md:px-12 mid-lg:px-24 lg:px-36 md:pt-36 lg:pt-40 tracking-wide font-thin md:font-light text-3xl md:text-5xl lg:text-5xl'>
                {header}
            </h1>
            <h1 className='w-full px-6 md:px-12 mid-lg:px-24 lg:px-36 md:pt-10 lg:pt-8 tracking-wide font-thin text-sm md:text-base lg:text-xl'>
                {subHeading}
            <div className='border-b pt-8'></div>
            </h1>
            <div className='pb-20 grid mid-lg:grid-cols-6 gap-6 w-full px-6 md:px-12 mid-lg:px-24 lg:px-36 py-4 md:pt-8 lg:pt-12'>
                <div className='mid-lg:col-span-4 grid md:flex md:flex-col md:justify-between'>
                    <img className="pb-8" src="https://dt7j04u1665qi.cloudfront.net/tenants-small.webp" srcSet="https://dt7j04u1665qi.cloudfront.net/tenants-small.webp 400w, https://dt7j04u1665qi.cloudfront.net/tenants-medium.webp 800w, https://dt7j04u1665qi.cloudfront.net/tenants-medium.webp 1200w" loading="lazy" alt="dfd" />
                    {dataToDisplay.data.map((ele, it) => {
                        return (<div key={it} className='grid grid-cols-11 md:grid-cols-10 py-4'>
                            <div className='col-span-2 md:col-span-1 py-2'>
                                <div className='tracking-widest font-bold text-gray-500 text-xs pb-4'>STEP</div>
                                <div className='text-5xl '>{it+1}</div>
                            </div>
                            <div className='border-l col-span-1'></div>
                            <div className='col-span-8'>
                                <div className='font-bold pb-4'>{ele.heading}</div>
                                <ul className="list-disc">{typeof(ele.desc) == "string" ? ele.desc : ele.desc.map(el => <li>{el}</li>)}</ul>
                            </div>
                        </div>)
                        }
                    )}
                </div>
                <div className='mid-lg:col-span-2 w-full max-h-80 lg:max-h-72 sticky top-24 bg-white mx-auto p-2 md:p-4 lg:p-6 shadow-lg border'>
                    <div className='py-1 px-4 flex flex-col justify-evenly'>
                        <div className='font-bold pb-4'>Want to invest in Dubai?</div>
                        <div className='pb-4'>With more than 50 international roadshows and over 10 billion of Sales, Haideries is ranked as the UAE's #1 Off-Plan Real Estate Advisory. Contact Us to know more.</div>
                        <div className="w-full bottom-0 flex text-xs">
                            <div className="w-1/2 bg-white text-white flex items-center justify-center pr-1 md:h-12">
                            <a href='https://wa.me/+971502607861' target="_blank" rel="noopener noreferrer" className="w-full bg-black flex items-center justify-center p-4 md:h-12">
                                <FontAwesomeIcon icon={faWhatsapp} className='px-2'/>WhatsApp
                            </a>
                            </div>
                            <div className="w-1/2 bg-white text-white flex items-center justify-center pl-1 md:h-12">
                            <a href='tel:+971502607861' target="_blank" rel="noopener noreferrer" className="w-full bg-black flex items-center justify-center p-4 md:h-12">
                                <FontAwesomeIcon icon={faPhone} className='px-2'/> CALL
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuideTemplate;
