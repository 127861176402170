import React from 'react';
import Header from '../Header/Header';

const CookiePolicy = () => {

    const handleClick = (link) => {
        window.location.href = link;
    };

    return (
        <>
            <Header visible={true} />
            <h1 className='w-11/12 md:w-5/6 px-6 md:px-12 mid-lg:px-24 lg:px-36 pt-20 md:pt-28 lg:pt-40 tracking-wide font-thin md:font-light text-2xl md:text-4xl lg:text-5xl'>
                COOKIE POLICY
            </h1>
            <div className='pb-20 grid mid-lg:grid-cols-6 gap-6 w-full px-6 md:px-12 mid-lg:px-24 lg:px-36 py-4 md:pt-8 lg:pt-12'>
                <div className='mid-lg:col-span-4 grid md:flex md:flex-col md:justify-between'>
                    <div className='grid grid-cols-11 md:grid-cols-10 py-4'>
                        <div className='col-span-11 md:col-span-11'>
                            <div className='pb-8'>
                                <ul className="list-disc">
                                    <p>At Allegiance Real Estate, we prioritize your privacy and data protection. To enhance your experience on our website, we utilize cookies and similar technologies. This cookie policy outlines our use of these technologies and provides information on how you can control or opt out of them.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>What are Cookies?</div>
                                <ul className="list-disc">
                                    <p>Cookies are small text files downloaded to your computer or device when you visit a website. They collect data about your interactions with the website, such as preferences, browsing history, and settings, to personalize content and improve overall user experience.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Types of Cookies</div>
                                <ul className="list-disc pl-6">
                                    <li><span className='font-semibold'>Essential Cookies:</span> Necessary for the website to function, including session and security cookies.</li>
                                    <li><span className='font-semibold'>Performance Cookies:</span> Track website usage, improving performance, speed, and functionality.</li>
                                    <li><span className='font-semibold'>Functionality Cookies:</span> Keep track of preferences like language and location, enhancing your experience.</li>
                                    <li><span className='font-semibold'>Targeting/Advertising Cookies:</span> Monitor browsing habits to deliver personalized advertising and assess marketing success.</li>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Cookie Management</div>
                                <p>You can control or disable cookies through your web browser settings. However, please note that enabling cookies may impact the functionality and effectiveness of the website.</p>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Privacy Protection</div>
                                <ul className="list-disc">
                                    <p>We prioritize the security of your privacy and data. We do not collect or utilize personal information through cookies without your consent. Additionally, data collected by our cookies is not shared with third parties for marketing purposes.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Modifications to this Policy:</div>
                                <ul className="list-disc">
                                    <p>We may update this cookie policy to reflect changes in our use of cookies and related technologies. Regularly reviewing our policy will keep you informed of any updates.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Contact Us</div>
                                <ul className="list-disc">
                                    <p>If you have questions or concerns about our use of cookies, please email us at <span className='cursor-pointer text-blue-300' onClick={() => handleClick('mailto:info@haideries.ae')}>info@haideries.ae</span>.</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookiePolicy;
