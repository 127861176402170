import React from "react";
import pfp1 from "../asset/SarahK.webp"
import pfp2 from "../asset/AhmadM.webp"
import pfp3 from "../asset/SophiaA.webp"
import pfp4 from "../asset/DavidL.webp"
import pfp5 from "../asset/AishaF.webp"
import { useState, useEffect } from "react";

function Testimonial() {
  const [isHovering, setIsHovering] = useState(false);
  const [pageWidth, setPageWidth] = useState('none');

  function handleMouseEnter() {
    setIsHovering(true);
  }

  function handleMouseLeave() {
    setIsHovering(false);
  }

  const testimony = [
    {
      name:"Sarah K.",
      occupation: "Investor",
      img: pfp1,
      msg: "Working with Haideries was an absolute game-changer for my investment portfolio. Their team guided me through every step. Thanks to Haideries I purchased multiple properties in Dubai, and their expertise maximized my ROI significantly."
    },
    {
      name:"Ahmed M.",
      occupation: "Homebuyer",
      img: pfp2,
      msg: "I can't recommend Haideries enough! As a first-time homebuyer, I had many doubts, but their agents were patient and incredibly knowledgeable. They helped me find the perfect home within my budget and made the entire process stress-free."
    },
    {
      name:"Sophia A.",
      occupation: "Seller",
      img: pfp3,
      msg: "Selling my property in Dubai was seamless, thanks to Haideries. Their marketing strategies attracted numerous potential buyers, and their negotiation skills ensured I got the best value for my property. I was thrilled with the results!"
    },
    {
      name:"David L.",
      occupation: "Investor",
      img: pfp4,
      msg: "Haideries' insight into the Dubai real estate market is unmatched. Their market analysis and recommendations were spot-on. I've made substantial gains from the properties they helped me invest in. Their professionalism is top-notch."
    },
    {
      name:"Aisha F.",
      occupation: "Renter",
      img: pfp5,
      msg: "Haideries made finding a rental property in Dubai hassle-free. Their agents were attentive, listened to my preferences, and presented several options that met my criteria. Renting my dream home was a breeze, all thanks to them!"
    },
  ]

  useEffect(() => {
    const updatePageWidth = () => {
      const width = window.innerWidth;
      if (width < 840) {
        setPageWidth('none');
      } else {
        setPageWidth('550px');
      }
    };

    updatePageWidth();

    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  const testimonies = testimony.map((ele, it) => {
    return <div key={it} className="flex-none m-2 w-2/3 md:pb-4 rounded-lg text-sm md:text-base" style={{width: `${pageWidth}`}}>
      <div className="bg-white p-4 md:p-6 mid-lg:w-11/12 lg:w-11/12 mx-auto rounded shadow-lg">
        <p className="mb-4">{ele.msg}</p>
        <div className="flex items-center">
          <img className="w-20 rounded-full " src={ele.img} loading="lazy" alt='ppl' />
          <div className="ml-4">
            <h2 className="font-bold">{ele.name}</h2>
            <h3>{ele.occupation}</h3>
          </div>
        </div>
      </div>
    </div>
  })

  return (
    <div id="Testimonials" className="bg-gray-200 flex flex-col py-8">
      <div>
        <div className="flex flex-col px-4 md:px-8 mid-lg:mx-24 lg:mx-48">
          <h1 className="font-bold my-2 text-2xl md:text-3xl lg:text-4xl">CLIENT'S TESTIMONIALS</h1>
          <p className="my-4 max-w-3xl text-sm md:text-base">
            Discover the positive impact we've made on the our clients by
            reading through their testimonials. Our clients have experienced our
            service and results, and they're eager to share their positive
            experiences with you.
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="mx-auto w-full w-5/6 px-4 mid-lg:px-24 lg:px-48 mid-lg:mx-48 overflow-x-scroll scroll-snap-type-x mandatory scrollbar-hide">
          <div className={` ${isHovering ? 'is-hovering' : ''}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <div className={`flex flex-row scroll-snap-align-center animate-scroll`}>
              {testimonies}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;