import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './Layout';
import App from './App';

import Market from './components/Market'
import SellYourProperty from './components/FooterComp/SellYourProperty'
import ListProperty from './components/ListProperty/ListProperty'
import Contact from './components/Contact'
import BuyWithHaideries from './components/FooterComp/BuyWithHaideries'
import BookValuation from './components/BookValuation'
import GuideToBuy from './components/FooterComp/GuideToBuy'
import GuideToSell from './components/FooterComp/GuideToSell'
import RentWithHaideries from './components/FooterComp/RentWithHaideries'
import GuideForTenants from './components/FooterComp/GuideForTenants'
import WhyChooseHaideries from './components/FooterComp/WhyChooseHaideries'
import TermsAndCondition from './components/FooterComp/TermsAndCondition'
import PrivacyPolicy from './components/FooterComp/PrivacyPolicy'
import CookiePolicy from './components/FooterComp/CookiePolicy'
import ComingSoon from './components/ComingSoon'
import Roadshow from './components/RoadShow'

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: "",
        element: <App />
      },
      {
        path: "connect",
        element: <Market />
      },
      {
        path: "/ListProperty",
        element: <ListProperty />
      },
      {
        path: "/SellYourProperty",
        element: <SellYourProperty />
      },
      {
        path: "/RoadShows",
        element: <Roadshow />
      },
      {
        path: "/Contact",
        element: <Contact />
      },
      {
        path: "/BuyWithHaideries",
        element: <BuyWithHaideries />
      },
      {
        path: "/BookValuation",
        element: <BookValuation />
      },
      {
        path: "/BuyersGuide",
        element: <GuideToBuy />
      },
      {
        path: "/SellersGuide",
        element: <GuideToSell />
      },
      {
        path: "/RentWithHaideries",
        element: <RentWithHaideries />
      },
      {
        path: "/TenantsGuide",
        element: <GuideForTenants />
      },
      {
        path: "/WhyHaideries",
        element: <WhyChooseHaideries />
      },
      {
        path: "/ComingSoon",
        element: <ComingSoon />
      },
      {
        path: "/TermsAndConditions",
        element: <TermsAndCondition />
      },
      {
        path: "/PrivacyPolicy",
        element: <PrivacyPolicy />
      },
      {
        path: "/CookiePolicy",
        element: <CookiePolicy />
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
