import React from 'react';
import FootCompTemplate from '../template/SellBuyRentTemplate';

const SellYourProperty = () => {
    return (
        <FootCompTemplate 
            heading={'SELL YOUR PROPERTY WITH'}
            subHeading={'LIST YOUR PROPERTIES WITH US'}
            desc={ 'There are several reasons why you may want to list your property with Haideries.'}
            paraText={'Explore the advantages of listing your properties with Haideries. Firstly, our adept agents possess the knowledge and expertise to accurately assess the ideal asking price for your property. Leveraging market data and insights, we empower you to make informed decisions, steering clear of the pitfalls of overpricing or undervaluing. This strategic approach not only attracts a broader pool of potential buyers but also ensures you secure the best possible price for your property.'}
            paraText2={'Secondly, our dedicated team excels in marketing your property effectively. Utilizing our extensive professional networks, we showcase your property to a wide audience, generating heightened interest. We go beyond by crafting polished listings and compelling advertisements that make your property stand out amidst the competition in today\'s dynamic real estate market. Trust Haideries to elevate your property listing experience and maximize its market potential.'}
        />
    );
};

export default SellYourProperty;