import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import toastTemplate from './toastTemplate';
const service = 'https://dt7j04u1665qi.cloudfront.net/24-7.webp'


function RequestCallback() {
    const {successToast, failureToast, WaitingToast, notify} = toastTemplate

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: ''
    });
    const [ready, setReady] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (!!formData.name && !!formData.email && !!formData.phoneNumber) {
            setReady(true)
        } else {
            setReady(false)
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name || !formData.email || !formData.phoneNumber) {
            alert('Please fill in all required fields.');
            return;
        }
        
        const waitId = WaitingToast()

        try {
            const response = await axios.post('https://7d4nmtpj4g.execute-api.us-east-1.amazonaws.com/first', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.statusCode === 200) {
                successToast(waitId)
                await setTimeout(() => {
                    notify("We will contact you shortly", "🫰🏼")
                }, 2000)
                setFormData({
                    name: '',
                    email: '',
                    phoneNumber: '',
                    propertyAddress: '',
                    message: '',
                })
                setReady(false)
            } else {
                failureToast(waitId)
                setTimeout(() => {
                    notify("Please Try Again", "😓")
                }, 2500)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const createObserver = (ref, setIsVisible) => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 }
        );
        return observer
    };

    const observeRef = (ref, observer) => {
        if (ref.current) {
            observer.observe(ref.current);
        }
    };

    const [isVisible, setIsVisible] = useState(false);
    const missionRef = useRef(null);

    useEffect(() => {
        const observer = createObserver(missionRef, setIsVisible);
        observeRef(missionRef, observer);

        if (missionRef.current) {
            observer.observe(missionRef.current);
        }

    }, [missionRef]);
    return (
        <>
            <div>
                <Toaster />
            </div>
            <div ref={missionRef} className='pb-10 px-4 md:px-8 mid-lg:mx-24 lg:mx-48 flex flex-col items-center'>
                <div className='md:grid md:grid-cols-8 w-full md:w-auto flex flex-col-reverse md:flex-row justify-center items-center'>
                    <div className='md:col-span-6'>
                        <h1 className={`text-2xl md:text-4xl mb-4 py-2 transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>REQUEST A CALLBACK</h1>
                        <p className={`md:text-lg transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            Whether you're contemplating investing in Dubai's real estate or seeking support in property management, simply drop your details below. Our experts will promptly connect with you, offering the necessary support and guidance for your real estate ventures.
                        </p>
                        <div className='py-4 md:text-x grid'>
                            <div className=''>
                                <div><FontAwesomeIcon icon={faCheck} className='mr-4' /> Get consultation in minutes</div>
                            </div>
                            <div>
                                <div><FontAwesomeIcon icon={faCheck} className='mr-4' /> 24x7 support from the marketing team</div>
                            </div>
                            <div>
                                <div><FontAwesomeIcon icon={faCheck} className='mr-4' /> Exposure to the right properties and communities</div>
                            </div>
                        </div>
                        <div>
                            <form className="flex flex-col md:flex-row" onSubmit={handleSubmit}>
                                <div className="mr-4 mb-4">
                                    <input
                                        type="text"
                                        id="name"
                                        placeholder='Name'
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full p-2 border border-gray-300"
                                        required
                                    />
                                </div>
                                <div className="mr-4 mb-4">
                                    <input
                                        type="email"
                                        placeholder='Email'
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full p-2 border border-gray-300"
                                        required
                                    />
                                </div>
                                <div className="mr-4 mb-4">
                                    <input
                                        type="tel"
                                        placeholder='Mobile number'
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        className="w-full p-2 border border-gray-300"
                                        required
                                    />
                                </div>
                                <div className="flex mr-4 md:mb-4">
                                    <button type="submit" className={`w-full p-4 md:py-0 bg-black tracking-widest text-white text-xs ${ready ? '' : `bg-gray-600 cursor-no-drop`}`}>
                                        SUBMIT
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className='text-xs md:text-sm'>
                            <p>By submitting the form, you agree to our <Link to='/TermsAndConditions' className='text-blue-800'>Terms & Conditions</Link> and <Link to='/PrivacyPolicy' className='text-blue-800'>Privacy Policy.</Link></p>
                        </div>
                    </div>
                    <img className={`md:col-span-2 pl-0 md:pl-2 mb-4 md:mb-0 transition-all duration-700 ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`} src={service} loading="lazy" alt="" />
                </div>
            </div>
        </>
    )
}

export default RequestCallback