import React from 'react'
import GuideTemplate from '../template/GuideTemplate'
import TenantsGuideData from '../../TenantsGuideData.json'

function GuideForTenants() {
  return (
    <div>
        <GuideTemplate 
            header={'LOOKING TO RENT A PROPERTY IN DUBAI?'}
            subHeading={'Tenants in Dubai have the right to a safe and secure living environment, as well as the right to privacy and peaceful enjoyment of their rental property. They are also entitled to receive necessary repairs and maintenance to the property in a timely manner.'}
            data={TenantsGuideData}
        />
    </div>
  )
}

export default GuideForTenants