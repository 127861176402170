import React from 'react'
import FootCompTemplate from '../template/SellBuyRentTemplate'

function BuyWithHaideries() {
  return (
    <FootCompTemplate 
        heading={'BUYING WITH HAIDERIES'}
        desc={'Haideries, a worldwide real estate brokerage firm, has successfully facilitated transactions exceeding 20 billion dirhams in Dubai. If you\'re seeking to purchase a home in Dubai, our proficient team is here to guide you in making well-informed decisions.'}
        paraText={'Discover the benefits of partnering with Haideries when buying your dream property. Firstly, our skilled agents possess the knowledge to precisely evaluate the optimal purchase price for your desired property. Through insightful market data, we empower you to make informed decisions, avoiding the pitfalls of overpaying or undervaluing. This strategic approach not only broadens the spectrum of available properties but also ensures you acquire your dream home at the best possible value.'}
        paraText2={'Secondly, our committed team excels in efficiently promoting properties for sale. Leveraging our extensive professional networks, we present your future home to a diverse audience, sparking heightened interest. We extend our efforts by creating polished property profiles and captivating advertisements that make your dream home stand out in today\'s dynamic real estate market. Trust Haideries to enhance your home-buying experience and maximize the potential of finding the perfect property.'}
    />
  )
}

export default BuyWithHaideries