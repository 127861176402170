import React, { useState, useEffect, useRef } from 'react';
import Card from './RoadShowCard'

function RoadShow() {
    const missionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (missionRef.current) {
            observer.observe(missionRef.current);
        }
    }, [missionRef]);

    return (
        <div ref={missionRef} className='w-full bg-colourBg text-white py-8 overflow-hidden'>
            <div className="mx-2 md:mx-8 mid-lg:mx-24 lg:mx-48 lg:px-6">
                <h1 className={`text-2xl md:text-3xl lg:text-4xl p-1 mb-12 ${isVisible ? 'animate-slideInLeft opacity-100' : 'opacity-0'}`}>UPCOMING INTERNATIONAL ROADSHOWS</h1>
                <p className={`px-1 mb-12 max-w-2xl tracking-wide text-xs md:text-sm transition-all duration-1000} ${isVisible ? 'animate-slideInRight opacity-100' : 'opacity-0'}`}>
                    Haideries has grown into a global real estate advisory thanks to our international property events, which helps us speak to the investors and give them insights on the real estate market of Dubai. Checkout the list of our upcoming international property events, below.
                </p>
                <Card />
                <div className='border bg-white border-white w-full my-8 md:my-20 ' />
                <div className='grid mid-lg:grid-cols-8 gap-4 md:gap-8 m-2 md:my-8'>
                    <div className={`md:tracking-widest mid-lg:col-span-2 text-xl md:text-2xl lg:text-3xl ${isVisible ? 'animate-slideInTop2 opacity-100' : 'opacity-0'}`}>WHY ATTEND ROADSHOWS?</div>
                    <div className={`mid-lg:col-span-6 text-sm md:text-lg w-full ${isVisible ? 'animate-slideInRight opacity-100' : 'opacity-0'}`}>Embark on an enlightening journey with Haideries Real Estate, having conducted over 200 international roadshows since 2021. Our endeavors have not only showcased the vibrant allure of Dubai but also emphasized the inviting atmosphere it extends to global investors. Discover comprehensive insights into the Dubai real estate market, property prices, mortgages, and exclusive offers directly from developers. Join us in unraveling the limitless possibilities that Dubai holds for you.</div>
                </div>
            </div>
        </div>
    );
}

export default RoadShow;
