import React from 'react';
import Header from '../Header/Header';

const PrivacyPolicy = () => {

    return (
        <>
            <Header visible={true} />
            <h1 className='w-11/12 md:w-5/6 px-6 md:px-12 mid-lg:px-24 lg:px-36 pt-20 md:pt-28 lg:pt-40 tracking-wide font-thin md:font-light text-2xl md:text-4xl lg:text-5xl'>
            PRIVACY POLICY
            </h1>
            <div className='pb-20 grid mid-lg:grid-cols-6 gap-6 w-full px-6 md:px-12 mid-lg:px-24 lg:px-36 py-4 md:pt-8 lg:pt-12'>
                <div className='mid-lg:col-span-4 grid md:flex md:flex-col md:justify-between'>
                    <div className='grid grid-cols-11 md:grid-cols-10 py-4'>
                        <div className='col-span-11 md:col-span-11'>
                            <div className='pb-8'>
                                <ul className="list-disc">
                                    <p>This privacy policy outlines how we handle the processing of personal information you provide to us. We will notify you of any changes in the processing of your information.</p><br />
                                    <p>You can navigate our website and access our home page without disclosing personal data, except for information collected by cookies (refer to the section below).</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Who may process data</div>
                                <ul className="list-disc">
                                    <p>Your personal information, including your name and address, may be processed by us and other companies within our group. Our website contains a link to Rackspace, and this company may also collect personal data from site visitors. All authorized companies processing your information will adhere to this privacy policy.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Purpose of processing</div>
                                <ul className="list-disc">
                                    <p>We will use your information to fulfill service orders, process authorized transactions, inform you of special offers, provide marketing information, and conduct customer research/development.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Disclosure of Information</div>
                                <p>In rare circumstances involving liquidation, administration, or receivership, a third-party purchaser of the business may receive your information, provided they agree to use it for the same purposes outlined in this policy. We commit not to disclose your personal information to third parties, except as specified in this policy. Your information will not be shared with government or local authorities, except as required by law.</p>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Cookies</div>
                                <ul className="list-disc">
                                    <p>When you visit our website, a small file may be sent to your computer to identify it, track your behavior, and identify your areas of interest. This helps enhance your future visits. We may collect and store personal data through cookies, linking this information with data you provide. Apart from the use of cookies, we do not automatically log or collect data unless explicitly provided by you. You can configure your browser to reject cookies, but this may limit your use of certain website features.</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicy;
