import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

function DescriptionTemplate({
    heading,
    desc,
    paraText,
    paraText2,
    subHeading
}) {
    const createObserver = (ref, setIsVisible) => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 }
        );
        return observer
    };

    const observeRef = (ref, observer) => {
        if (ref.current) {
            observer.observe(ref.current);
        }
    };

    const [isVisible, setIsVisible] = useState(false);
    const missionRef = useRef(null);

    useEffect(() => {
        const observer = createObserver(missionRef, setIsVisible);

        observeRef(missionRef, observer);

        if (missionRef.current) {
            observer.observe(missionRef.current);
        }

    }, [missionRef]);

    return (
        <>
            <div ref={missionRef} className='py-10 md:py-20 px-4 md:px-8 mid-lg:mx-24 lg:mx-48 flex flex-col items-center'>
                <div className='w-full md:w-auto flex flex-col md:flex-row justify-center'>
                    <div className='w-full md:w-1/2 pr-0 md:pr-8'>
                        <h1 className={`text-2xl md:text-3xl mb-4 font-thin lg:pb-2 transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>
                            {subHeading}
                        </h1>
                        <h1 className={`text-sm font-semibold md:text-base mb-4 lg:pb-4 transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'}`}>
                            {desc}
                        </h1>
                        <p className={`text-sm md:text-base transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'}`}>
                            {paraText}
                            <br />
                            <br />
                            {paraText2}
                        </p>
                    </div>
                    <div className={`w-full md:w-1/2 pl-0 md:pl-2 mb-4 md:mb-0 transition-all duration-700 ${isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'}`} >
                        <div className='md:text-3xl grid grid-cols-2 gap-2 md:gap-16 my-4'>
                            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
                                <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faCreditCard} />
                                <div className='m-2 mx'>
                                    <div>20 BILLION</div>
                                    <div className='text-sm'>Total Value</div>
                                </div>
                            </div>
                            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
                                <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faLanguage} />
                                <div className='m-2 mx-4'>
                                    <div>20 +</div>
                                    <div className='text-sm'>Language Spoken</div>
                                </div>
                            </div>
                            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
                                <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faUser} />
                                <div className='m-2 mx-4'>
                                    <div>4.9/5</div>
                                    <div className='text-sm'>Customer Rating</div>
                                </div>
                            </div>
                            <div className="flex border-b" style={{ borderColor: "#4B6587" }}>
                                <FontAwesomeIcon className='py-2 text-5xl text-bluColor' icon={faHouse} />
                                <div className='m-2 mx-4'>
                                    <div>5000+</div>
                                    <div className='text-sm'>Properties Sold so far</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DescriptionTemplate