import React from 'react'
import FootCompTemplate from '../template/SellBuyRentTemplate'

function RentWithHaideries() {
  return (
    <FootCompTemplate 
        heading={'WHY RENT WITH HAIDERIES?'}
        subHeading={'GET AMAZING RENTAL OPTIONS WITH HAIDERIES'}
        desc={'Are you looking to rent your property or looking to rent a property for yourself? Haideries is here to support you in each step you take towards renting.'}
        paraText={'If you\'re looking to rent your property in Dubai, start by setting a competitive rental price based on research into similar properties in the area. Advertise your property effectively through online platforms, real estate agents, or local publications, providing detailed information and attractive photos. When inquiries come in, carefully screen potential tenants by conducting background checks and verifying employment and income details. Once you find a suitable tenant, proceed with the rental agreement to complete the process.'}
        paraText2={'On the other hand, if you\'re seeking to rent a property in Dubai, begin by defining your budget and priorities. Explore online listings, contact real estate agents, and check local advertisements to find suitable options. Inspect potential rentals thoroughly, considering factors like size, location, and amenities. Haideries can assist you throughout the rental process, offering support in each step to ensure a smooth experience.'}
    />
  )
}

export default RentWithHaideries