import React from 'react'
import ListProperty from './ListProperty/ListProperty'

function BookValuation() {
    return (
        <ListProperty
            header={'FIND VALUATION OF YOUR PROPERTY'}
            paraText={'Find out what the current market value of your property actually is! Haideries Real Estate can provide you with the correct facts as well as valuable insight.'}
        />
    )
}

export default BookValuation