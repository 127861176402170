import React from 'react'
import ListProperty from './ListProperty/ListProperty'

function Contact() {
    return (
        <ListProperty
            header={'GET IN TOUCH WITH HAIDERIES REAL ESTATE'}
            paraText={'Our expert team is dedicated to providing you with accurate information on property prices in Dubai. As a global real estate advisory firm, we specialize in connecting customers with the perfect investors to meet their needs.'} />
    )
}

export default Contact