import React from 'react';
import Header from '../Header/Header';

const GuideTemplate = () => {

    return (
        <>
            <Header visible={true} />
            <h1 className='w-11/12 md:w-5/6 px-6 md:px-12 mid-lg:px-24 lg:px-36 pt-20 md:pt-28 lg:pt-40 tracking-wide font-thin md:font-light text-2xl md:text-4xl lg:text-5xl'>
                TERMS AND CONDITIONS FOR HAIDERIES REAL ESTATE LLC
            </h1>
            <div className='pb-20 grid mid-lg:grid-cols-6 gap-6 w-full px-6 md:px-12 mid-lg:px-24 lg:px-36 py-4 md:pt-8 lg:pt-12'>
                <div className='mid-lg:col-span-4 grid md:flex md:flex-col md:justify-between'>
                    <div className='grid grid-cols-11 md:grid-cols-10 py-4'>
                        <div className='col-span-11 md:col-span-11'>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Introduction</div>
                                <ul className="list-disc">
                                    <p>Welcome to our website! These Website Standard Terms and Conditions, detailed on this webpage, are designed to govern your use of Website Name, which is accessible at Website.com.</p><br />
                                    <p>By using this Website, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Website Standard Terms and Conditions, please refrain from using the Website. Additionally, individuals under 18 years of age or minors are not permitted to use this Website.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Intellectual Property Rights</div>
                                <ul className="list-disc">
                                    <p>All intellectual property rights and materials contained on this Website, excluding your own content, are owned by Company Name and/or its licensors. You are granted a limited license for the sole purpose of viewing the material on this Website.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Restrictions</div>
                                <p>You are expressly restricted from the following activities:</p><br />
                                <ul className="list-disc pl-4">
                                    <li>Publishing Website material in any other media.</li>
                                    <li>Selling, sublicensing, or commercializing any Website material.</li>
                                    <li>Publicly performing or showing any Website material.</li>
                                    <li>Using this Website in a manner that may be damaging to it.</li>
                                    <li>Using this Website in a way that impacts user access.</li>
                                    <li>Using this Website contrary to applicable laws and regulations.</li>
                                    <li>Engaging in data mining, harvesting, extracting, or similar activities.</li>
                                    <li>Using this Website for advertising or marketing.</li>
                                </ul>
                                <br /><p>Access to certain areas may be restricted by Company Name, and user IDs and passwords must be kept confidential.</p>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Your Content</div>
                                <ul className="list-disc">
                                    <p>"Your Content" refers to any audio, video, text, images, or other material you choose to display on this Website. By displaying Your Content, you grant Company Name a non-exclusive, worldwide, irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any media. Your Content must not violate any third-party rights, and Company Name reserves the right to remove it without notice.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>No Warranties</div>
                                <ul className="list-disc">
                                    <p>This Website is provided "as is," with no representations or warranties from Company Name. Nothing on this Website should be interpreted as advice.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Limitation of Liability</div>
                                <ul className="list-disc">
                                    <p>Company Name, its officers, directors, and employees shall not be liable for anything arising from or connected with your use of this Website, under contract or otherwise. Company Name shall not be held liable for any indirect, consequential, or special liability related to your use of this Website.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Indemnification</div>
                                <ul className="list-disc">
                                    <p>You agree to indemnify Company Name from any liabilities, costs, demands, causes of action, damages, and expenses arising from your breach of these Terms.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Severability</div>
                                <ul className="list-disc">
                                    <p>If any provision of these Terms is found to be invalid under applicable law, it shall be deleted without affecting the remaining provisions.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Variation of Terms</div>
                                <ul className="list-disc">
                                    <p>Company Name may revise these Terms at any time, and your continued use of the Website implies your agreement to review them regularly.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Assignment</div>
                                <ul className="list-disc">
                                    <p>Company Name can assign, transfer, and subcontract its rights and/or obligations under these Terms without notification, but you cannot do the same without permission.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Entire Agreement</div>
                                <ul className="list-disc">
                                    <p>These Terms constitute the entire agreement between Company Name and you regarding your use of this Website, superseding all prior agreements and understandings.</p>
                                </ul>
                            </div>
                            <div className='pb-8'>
                                <div className='font-bold pb-4'>Governing Law & Jurisdiction</div>
                                <ul className="list-disc">
                                    <p>These Terms are governed by the laws of the State of Country, and you submit to the non-exclusive jurisdiction of state and federal courts in the Country for dispute resolution.</p>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuideTemplate;
